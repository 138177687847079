@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #ffffff;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--bg-primary);
  overflow: hidden;
}

.video-chat-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 2px;
  height: 100vh;
  margin-top: 0;
  padding-top: 0;
}

.video-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 20px;
  background-color: var(--bg-secondary);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  height: 100%;
  box-sizing: border-box;
}

.video-wrapper {
  position: relative;
  margin-bottom: 20px;
  height: 40vh;
}

video {
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 8px;
  object-fit: cover;
}

video:not([srcObject]) {
  background-color: #000;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #555;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
  background-color: var(--bg-primary);
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  max-width: 70%;
  margin: 5px 0;
  display: flex;
  width: 100%;
}

.message-own {
  justify-content: flex-end;
  margin-left: auto;
}

.message-partner {
  justify-content: flex-start;
  margin-right: auto;
}

.message-content {
  padding: 10px 15px;
  border-radius: 15px;
  word-break: break-word;
  max-width: fit-content;
}

/* Dark Mode Nachrichten */
[data-theme='dark'] .message-own .message-content {
  background-color: var(--accent-color);
  color: white;
}

[data-theme='dark'] .message-partner .message-content {
  background-color: var(--bg-secondary);
  color: white;
}

/* Light Mode Nachrichten */
[data-theme='light'] .message-own .message-content {
  background-color: #2ecc71;
  color: white;
}

[data-theme='light'] .message-partner .message-content {
  background-color: #e9ecef;
  color: #000000;
}

/* Zeitstempel in Nachrichten */
.message-time {
  font-size: 0.75em;
  opacity: 0.7;
  margin-left: 5px;
  white-space: nowrap;
  align-self: flex-end;
}

[data-theme='light'] .message-time {
  color: #666; /* Dunklere Farbe für Zeitstempel im Light Mode */
}

.chat-input-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: var(--bg-secondary);
  border-top: 1px solid var(--border-color);
  gap: 10px;
  z-index: 100;
}

.chat-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.chat-input-container input:focus {
  outline: none;
  background-color: #444;
}

.chat-input-container input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chat-input-container input::placeholder {
  color: #888;
}

.chat-input-container button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #2ecc71;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.chat-input-container button:hover {
  background-color: #27ae60;
  transform: scale(1.1);
}

.chat-input-container button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  transform: none;
}

/* Scrollbar Styling */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #3c3c3c;
  border-radius: 3px;
}

.control-button {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 1.2em;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.control-button:hover {
  background-color: var(--bg-hover);
}

.control-button.active {
  background-color: var(--accent-color);
  color: white;
}

.main-button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.main-button:hover:not(:disabled) {
  transform: scale(1.05);
}

.main-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.main-button.searching {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.device-select {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 250px;
  display: none;
}

.device-select.show {
  display: block;
}

.device-select select {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.device-select label {
  display: block;
  margin-top: 10px;
  color: var(--text-primary);
}

/* Responsive Anpassungen für das Device-Menü */
@media (max-width: 600px) {
  .device-select {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 90%;
    max-width: 300px;
  }
}

.device-group {
  margin-bottom: 10px;
}

.device-group:last-child {
  margin-bottom: 0;
}

.device-group label {
  display: block;
  margin-bottom: 5px;
  color: #aaa;
  font-size: 0.9em;
}

.device-group select {
  width: 100%;
  padding: 6px;
  background-color: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 5px;
  font-size: 0.9em;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.partner-info {
  background: rgba(95, 95, 95, 0.9);
  border-radius: 15px;
  padding: 10px 20px;
  margin: 10px auto;
  max-width: 80%;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.partner-info p {
  margin: 0;
  font-size: 1.1em;
  color: #333;
}

.country-flag {
  font-family: 'Noto Color Emoji', sans-serif;
  font-size: 1.3em;
  margin-left: 8px;
  vertical-align: middle;
}

.language-selector {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.language-selector:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.language-selector option {
  background-color: #1e1e1e;
  color: white;
  padding: 8px;
}

.error-message {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 82, 82, 0.9);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.emoji-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0 10px;
  transition: transform 0.2s;
  color: var(--text-primary);
}

.emoji-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.emoji-button:not(:disabled):hover {
  transform: scale(1.1);
}

.emoji-picker-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.emoji-picker-react {
  background-color: var(--bg-secondary) !important;
  border-color: var(--border-color) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.emoji-picker-react .emoji-group:before {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.typing-indicator {
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  width: fit-content;
}

.typing-dots {
  display: flex;
  gap: 4px;
}

.typing-dots span {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

.typing-dots span:nth-child(1) { animation-delay: 0.1s; }
.typing-dots span:nth-child(2) { animation-delay: 0.2s; }
.typing-dots span:nth-child(3) { animation-delay: 0.3s; }

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Sound Toggle Button */
.sound-toggle {
  background: none;
  border: none;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sound-toggle.disabled {
  opacity: 0.5;
}

.sound-toggle:hover {
  transform: scale(1.1);
}

/* Theme Styles */
:root {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --text-primary: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #2ecc71;
}

[data-theme='light'] {
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --text-primary: #000000;
  --text-secondary: #666666;
  --accent-color: #2ecc71;
}

.App {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.video-container {
  background-color: var(--bg-secondary);
}

.chat-container {
  background-color: var(--bg-primary);
}

.message-own .message-content {
  background-color: var(--accent-color);
}

.message-partner .message-content {
  background-color: var(--bg-secondary);
}

/* Top Controls */
.top-controls {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: none;
}

.theme-toggle {
  background: none;
  border: none;
  color: var(--text-primary);
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.connection-quality {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  transition: all 0.3s ease;
  z-index: 10;
  display: none;
}

.connection-quality.good {
  color: #2ecc71;
}

.connection-quality.medium {
  color: #f1c40f;
}

.connection-quality.poor {
  color: #e74c3c;
}

.connection-quality i {
  font-size: 1.2em;
}

/* Tooltip-Stil für die Qualitätsanzeige */
.connection-quality:hover::after {
  content: attr(title);
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 10px;
}

.emoji-wrapper {
  position: relative;
  z-index: 1000;
}

/* Stelle sicher, dass der Emoji-Picker über allem anderen liegt */
.emoji-picker-container {
  position: absolute;
  bottom: 120%;
  left: 0;
  z-index: 1000;
}

/* Verhindere, dass der Emoji-Picker vom Container abgeschnitten wird */
.chat-container {
  position: relative;
  overflow: visible;
}

.has-partner .connection-quality {
  display: block;
}

/* Emoji-Picker Styles verfeinern */
.emoji-picker-react {
  background-color: var(--bg-secondary) !important;
  border-color: var(--border-color) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

/* Kategorie-Buttons im Emoji-Picker */
.emoji-picker-react .emoji-categories button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 5px !important;
}

.emoji-picker-react .emoji-categories button img {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.emoji-picker-react .emoji-categories {
  padding: 0 10px !important;
}

/* Emoji-Container Position */
.emoji-picker-container {
  position: absolute;
  bottom: 120%;
  left: 0;
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
}

/* Emoji-Button */
.emoji-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0 10px;
  transition: transform 0.2s;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Light Mode Anpassung */
[data-theme='light'] .chat-input-container input {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ddd;
}

/* Emoji Picker Kategorie-Buttons verbessern */
.emoji-picker-react .emoji-categories {
  display: flex !important;
  justify-content: space-around !important;
  padding: 5px !important;
}

.emoji-picker-react .emoji-categories button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.emoji-picker-react .emoji-categories button img {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
  object-fit: contain !important;
}

/* Emoji Picker Container Position */
.emoji-picker-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Light/Dark Mode für Emoji Picker */
[data-theme='light'] .emoji-picker-react {
  background-color: #ffffff !important;
  border-color: #ddd !important;
}

[data-theme='dark'] .emoji-picker-react {
  background-color: var(--bg-secondary) !important;
  border-color: var(--border-color) !important;
}

/* Media Queries für verschiedene Geräte */

/* Smartphones im Hochformat */
@media (max-width: 600px) {
  .video-chat-container {
    flex-direction: column;
  }

  .video-container {
    width: 100%;
    padding: 10px;
  }

  .chat-container {
    height: 50vh;
  }
}

/* Tablets im Hochformat */
@media (min-width: 601px) and (max-width: 900px) {
  .video-container {
    width: 50%;
  }
}

/* Laptops und größere Bildschirme */
@media (min-width: 901px) {
  .video-container {
    width: 35%;
  }
} 